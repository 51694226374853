<template>
  <div class="playerPage">
    <div class="tit_o">
      <div class="tit_left">
        <span class="back_but" @click="goPlayer" />{{ infosort }}：{{
          infotitleThree
        }}
      </div>
      <div class="search">
        <el-autocomplete
          ref="SearchAsync"
          v-model="state"
          :trigger-on-focus="false"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入内容"
          @select="handleSelect"
        />
        <span class="search_but" @click="goSearch">搜索</span>
      </div>
    </div>
    <div class="box_o">
      <div v-if="videoUrl">
        <video
          ref="video_1"
          :src="videoUrl"
          :poster="imgUrl"
          preload="auto"
          controls="controls"
          class="video_con"
        />
      </div>
      <div v-if="!videoUrl" class="video_ton">视频正在录制中，敬请期待！</div>
      <div class="list_con">
        <div class="level_o">
          <div>{{ infoData.titleOne }}</div>
          <div class="titleTwo_o">
            {{ infoData.titleTwo }}
          </div>
        </div>
        <div class="list_scroll">
          <div
            v-for="(item, index) in infoData.info"
            :key="index"
            class="list_li"
            :id="'item' + item.id"
            :ref="'item' + item.id"
            :class="{ list_act: item.title == infoData.titleThree }"
            @click="switchVideo(item.id)"
          >
            {{ item.sort }}：{{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerPage',
  props: {},
  data() {
    return {
      state: '',
      multiple: false,
      clearable: true,
      loading: false,
      optionsData: [],
      // https://platform-public.oss-cn-beijing.aliyuncs.com/video/6a7db058355f015048a135c1def0bb35.mp4
      videoUrl: '',
      imgUrl: '',
      activeId: '',
      infoData: [],
      infotitleOne: '',
      infotitleTwo: '',
      infosort: '',
      infotitleThree: '',
      prohibit: false
    }
  },
  computed: {},
  watch: {
    'infoData.info': function (val) {
      const that = this

      this.$nextTick(() => {
        document.getElementById('item' + that.$route.query.id).scrollIntoView()
      })
    }
  },
  created() {
    const self = this

    self.$route.query.id
      ? localStorage.setItem('videoId', self.$route.query.id)
      : ''
    self.getVideo(self.$route.query.id || localStorage.getItem('videoId'))
  },
  mounted() {},
  updated() {
    if (this.$refs.video_1) {
      this.$refs.video_1.pause()
    }
  },
  methods: {
    querySearchAsync(queryString, cd) {
      const self = this
      if (queryString.length < 2) return
      self
        .$ajax({
          url: '/je/product/crm/mv/videoSearch',
          data: {
            input: queryString
          }
        })
        .then((request) => {
          if (!request.data.success) return
          let datas = []
          if (!request.data.obj.info) {
            datas = [
              {
                value: '没有查询结果，请重新输入条件'
              }
            ]
          } else {
            datas = request.data.obj.info.map((item) => ({
              value: `${item.titleTwo}  ${item.title}`,
              id: item.id
            }))
          }
          cd(datas)
        })
        .catch(() => {})
    },
    handleSelect(item) {
      if (!item) return
      const self = this
      if (item.id) {
        self.getVideo(item.id)
      }
    },
    goSearch() {
      const self = this
      self.$refs.SearchAsync.focus()
    },
    switchVideo(e) {
      const self = this
      if (self.$refs.video_1) {
        self.$refs.video_1.currentTime = 0
      }
      self.getVideo(e)
    },
    // 返回
    goPlayer() {
      const self = this
      if (self.$refs.video_1) {
        self.$refs.video_1.pause()
      }
      self.$router.push({
        path: '/videoCourse'
      })
    },
    getVideo(id) {
      const self = this
      if (self.prohibit) {
        return
      }
      self.prohibit = true
      self
        .$ajax({
          url: '/je/product/crm/mv/videoDetails',
          data: {
            id
          }
        })
        .then((request) => {
          self.prohibit = false
          if (!request.data.success) return
          self.infoData = request.data.obj
          self.imgUrl = request.data.obj.img || ''
          self.videoUrl = request.data.obj.url || ''
          self.infotitleOne = request.data.obj.titleOne
          self.infotitleTwo = request.data.obj.titleTwo
          self.infosort = request.data.obj.sort
          self.infotitleThree = request.data.obj.titleThree
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="less">
.search {
  text-align: center;
  display: flex;
  .el-select .el-input__inner {
    cursor: auto;
  }
  .el-input {
    border: 0px;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    height: 38px;
  }
  input {
    cursor: auto;
    width: 345px;
    font-size: 14px !important;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    height: 38px;
    color: rgba(238, 238, 238);
    border: 0px solid;
    background-color: #696969;
  }
  .search_but {
    cursor: pointer;
    border: 0;
    width: 90px;
    height: 38px;
    background: rgba(255, 48, 65, 1);
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 254, 254, 1);
    line-height: 40px;
  }
}
</style>

<style lang="less" scoped>
.playerPage {
  text-align: center;
  background: rgba(47, 47, 47, 1);
  height: 700px;
  margin-bottom: -100px;
  .tit_o {
    width: 1200px;
    margin: auto;
    height: 86px;
    font-size: 22px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tit_left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .back_but {
        cursor: pointer;
        display: inline-block;
        margin: 10px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        width: 15px;
        height: 15px;
        transform: rotate(45deg);
      }
    }
  }
  .box_o {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .video_ton {
      width: 100%;
      color: #fff;
      padding-top: 100px;
      width: 855px;
      height: 462px;
    }
    .video_con {
      object-fit: contain;
      width: 855px;
      height: 462px;
    }
    .list_con {
      width: 345px;
      height: 438px;
      background: rgba(17, 17, 17, 0.4);
      text-align: left;
      padding: 12px 0;
      .level_o {
        height: 69px;
        padding: 0px 20px;
        display: line-block;
        font-size: 18px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 28px;
        .titleTwo_o {
          font-size: 16px;
        }
      }
      .list_scroll {
        height: 369px;
        overflow: scroll;
        .list_li {
          cursor: pointer;
          padding: 0px 20px;
          height: 66px;
          line-height: 66px;
          font-size: 14px;
          font-weight: 400;
          color: rgb(255, 255, 255);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          &:hover {
            background: rgba(0, 0, 0, 1);
            border-right: 2px solid #fff;
          }
        }
        .list_act {
          background: rgba(0, 0, 0, 1);
          color: rgba(255, 48, 65, 1);
          border-right: 2px solid #fff;
        }
      }
    }
  }
}
</style>
